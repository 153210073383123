























































































































































































































































































































































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import TopBg from '../fair/component/detailTopBg.vue';
import { ResponseCode } from '~/util/constant';
import FairItem from '~/pages/home/component/FairItem.vue';
import Pay from './component/pay.vue';

export default Vue.extend({
  name: 'MerchantDetail',
  layout: 'layout2',
  components: { TopBg, FairItem, Pay },
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const { fairId } = ctx.query;
    const periodicalId = _.get(ctx, 'query.periodicalId', null);
    const id = _.get(ctx, 'params.id', null);

    const requestList = [
      ctx.$axios.get<any, any>(`/api/exhibition/${fairId}`),
      ctx.$axios.get<any, any>(`/api/companyApi/byId/${id}`), // 企业详情
      ctx.$axios.get(`/api/periodicalApi/periodicalId/${periodicalId}`), // 会刊详情
    ];
    const reps = await Promise.all(requestList);
    const [fairRes, companyRes, periodicalResult] = reps;

    let fair = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      fair = fairRes.data || {};
    }

    let company: any = {};
    let companyOldName = '';
    let companyIndustry: any = {};
    let productList = [];

    if (companyRes.code === ResponseCode.SUCCESS) {
      company = companyRes.data || {};
      if (!company.companyGovRegister) {
        company.companyGovRegister = {
          id: '',
          companyCode: '',
          issueTime: '',
          provinceShort: '',
          city: '',
          companyName: '',
          enName: '',
          capitalUnit: '',
          insuranceCount: '',
          province: '',
          legalPerson: '',
          oldName: '',
          orgCode: '',
          honorTags: '',
          legalPersonType: '',
          miniCompanyFlag: '',
          companyTypeDesc: '',
          companyType: '',
          revokeReason: '',
          revokeTime: '',
          industryFirstCode: '',
          industryAll: '',
          capitalAmount: null,
          licensedProject: '',
          cancelReason: null,
          cancelTime: null,
          realCapitalAmount: null,
          address: '',
          establishmentTime: '',
          taxCode: '',
          stockCode: '',
          insuranceCountFlag: '',
          businessScope: '',
          realCapitalUnit: '',
          authority: '',
          district: '',
          operationStartTime: '',
          generalProject: '',
          operationEndTime: '',
          creditNo: '',
          statusFlag: '',
        };
      }
      console.log('企业详情信息', company);
      companyOldName = company.companyGovRegister?.oldName
        ? JSON.parse(company.companyGovRegister.oldName)
        : [];
      companyIndustry = company.companyGovRegister?.industryAll
        ? JSON.parse(company.companyGovRegister.industryAll)
        : {};
      productList = company.productName ? company.productName.split(',') : [];
    }
    let staffList: any = [];
    let mobileList: any = [];
    let wechatList: any = [];
    let emailList: any = [];
    if (company.id) {
      console.log('获取联系人');
      // 联系人列表
      let staffRes = await ctx.$axios.post<any, any>(
        `/api/companyApi/staffList`,
        { companyId: company.id, pageNum: 1, pageSize: 50 }
      );
      console.log('联系人结果', staffRes);
      if (staffRes.code === ResponseCode.SUCCESS) {
        // company = res.data || {};
        let staffResult = staffRes.data.records;
        staffResult.map((item: any) => {
          let str = '';
          if ((item.source ?? '') != '') {
            str = item.source?.includes('(')
              ? item.source.split('(')[0]
              : item.source;
          } else {
            str = '[聚展网]';
          }
          console.log(506, str);
          item['sourceStr'] = str;
          if (item.phone) mobileList.push(item);
          if (item.weChat) wechatList.push(item);
          if (item.email) emailList.push(item);
        });
        staffList = staffResult;
        console.log('联系人列表', mobileList);
      }
    }
    let periodicalDetail = null;
    if (periodicalResult.code === ResponseCode.SUCCESS) {
      periodicalDetail = periodicalResult.data || {};
    }
    console.log('690-690', fair);

    return {
      langApi,
      periodicalId,
      fairId,
      detail: fair,
      company,
      companyOldName,
      companyIndustry,
      productList,
      staffList,
      mobileList,
      wechatList,
      emailList,
      periodicalDetail,
    };
  },
  data(): any {
    return {
      detail: {},
      swiperData: [1, 2, 3, 4, 5],
      sameSwiperOption: {
        slidesPerView: 4,
        spaceBetween: 10,
        autoplay: 3000, // l轮播间隔时间
        loop: false, //
        lazy: {
          // 懒加载
          loadPrevNext: true,
          loadPrevNextAmount: 2,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      fair: [],
      insuranceCountFlagOption: {
        '-1': '未公示',
        1: '1-50',
        2: '50-100',
        3: '100-500',
        4: '500-1000',
        5: '1000-5000',
        6: '5000-10000',
        7: '10000+',
      },
      contactsTel: '******',
      companyEmail: '******',
      companyUrl: '******',
      hasOrder: false,
      contactActive: 0,
      showPay: false,
    };
  },
  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
  },
  created() {
    this.fetchHasOrder();
    this.getintyExh();
  },
  methods: {
    handlePayClose() {
      this.showPay = false;
    },
    handleContactTab(e: any) {
      this.contactActive = e;
    },
    getCompanyName(str: string) {
      return str.slice(0, 2) || '';
    },
    handleLogin() {
      if (!this.isLogin) {
        return this.$login.show();
      }
      if (!this.hasOrder) {
        this.showPay = true;
        // window.open(
        //   `/periodical/${this.fairId}.html?periodicalId=${this.periodicalId}`
        // );
      }
    },
    handleShow(str: string) {
      this[str] = this.company[str];
    },
    async fetchHasOrder() {
      const res = await this.$axios.post('/api/exhibitorApi/checkUser', {
        periodicalId: this.periodicalId,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.hasOrder = res.data;
      }
    },
    async getintyExh() {
      const res = await this.$axios.post('/api/exhibition/list', {
        locale: this.langApi,
        industryId: this.detail.firstIndustryId,
        selectTimeType: 1,
        // industryId: _.get(industry, '[0].id', null), // 行业展会
        regionType: this.detail.regionType,
        pageNum: 1,
        pageSize: 5,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.fair = res.data.records;
      }
    },
  },
  head(): Record<string, any> {
    const {
      startTime = '',
      endTime = '',
      exhibitionName = '',
      industry = '',
      organizer = '',
      pavilionInfo = {},
    } = this.$data.detail;
    const { areaName = '', regionName = '', pavilionName = '' } = pavilionInfo;

    const industrys = industry?.split(',');
    const industryName = _.get(industrys, '[0]', '');

    return {
      title: `${startTime.slice(0, 4)}${exhibitionName}_代参展-聚展网`,
      meta: [
        {
          name: 'keywords',
          content: `${areaName}${industryName}展,${regionName}${industryName}展`,
        },
        {
          name: 'description',
          content: `展会时间:${startTime}至${endTime},展会地点:${pavilionName},主办单位:${organizer},聚展网提供展位预订,展会门票,展会行程,展会搭建等服务`,
        },
      ],
    };
  },
});
