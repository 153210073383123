






















































































































import Vue from 'vue';
import _ from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { ResponseCode } from '~/util/constant';
import { $cookies } from '~/util/api';
import { getXOssProcess, message } from '~/util/common';
// import { serviceJournal } from '~/pages/fair/service';
import { TraceCode } from '~/util/trace';

export default Vue.extend<any, any, any>({
  name: 'PeriodicalPay',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    }, // 会刊详情
    exhibitionInfo: {
      type: Object,
      default: () => {},
    }, //展会详情
  } as any,
  filters: {
    hasPhoneOrEmail(containPhoneEmail: string): string {
      if (!containPhoneEmail) return '';
      const val: string[] = [];
      // const { containPhoneEmail = [] } = this.newPeriodical;
      if (containPhoneEmail.includes('0')) {
        val.push('电话');
      }
      if (containPhoneEmail.includes('1')) {
        val.push('邮箱');
      }
      if (containPhoneEmail.includes('2')) {
        val.push('官网');
      }
      return val.length > 0 ? `含${val.join('、')}` : '';
    },
  },

  data(): Record<string, any> {
    return {
      period: {},
      rules: {
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' },
        ],
        concatTel: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
        ],
        concatMail: [
          { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' },
        ],
      },
      ruleForm: {
        periodicalId: 0,
        contactName: '',
        concatTel: '',
        concatMail: '',
      },
      periodicalList: [],
      periodical: {},
      // countPrice: '0.00',
      submitDisabled: false,
      isSdkReady: false, // 客戶sdk初始化判断
    };
  },
  computed: {
    countPrice() {
      return this.info.price;
    },
  },
  watch: {
    // value: {
    //   handle(value) {
    //     console.log('186-186支付组件', value);
    //     if (value) {
    //       const ruleForm = sessionStorage.getItem('periodOrderForm') || '{}';
    //       try {
    //         this.ruleForm = JSON.parse(ruleForm);
    //         if (this.periodicalId) {
    //           this.$set(this.ruleForm, 'periodicalId', this.periodicalId);
    //         }
    //         sessionStorage.removeItem('periodOrderForm');
    //       } catch (error) {}
    //       const phone = this.$cookies.get('phoneNumber') || '';
    //       this.$set(this.ruleForm, 'concatTel', `${phone}`);
    //     }
    //   },
    // },
    // ruleForm: {
    //   handler(value) {
    //     let periodical: any = this.periodical;
    //     if (value.periodicalId) {
    //       periodical = this.periodicalList.find(
    //         (item: any) => item.id === +value.periodicalId
    //       );
    //       this.periodical = periodical;
    //     }
    //     this.countPrice = _.get(periodical, 'price', '0.00');
    //   },
    //   deep: true,
    // },
  },
  methods: {
    getXOssProcess,
    handleClose() {
      this.$emit('on-close');
    },
    init() {
      window.ysf('onready', () => {
        this.isSdkReady = true;
      });
    },

    handleItemClick(id: number) {
      this.$set(this.ruleForm, 'periodicalId', id);
    },
    shouldLogin(): boolean {
      const access_token = $cookies.get('access_token');
      // 获取不到token为未登录, 前往登录页
      if (!access_token) {
        this.$login.show();
        // this.$router.push(`/login?redirect=${encodeURIComponent(this.$route.fullPath)}`);
        sessionStorage.setItem(
          'periodOrderForm',
          JSON.stringify(this.ruleForm)
        );
      }
      return !!access_token;
    },
    async submitData() {
      console.log('256-256', this.info);
      console.log(255, this.exhibitionInfo);
      // return false;
      const { userId } = this.$store.state.global.user;
      // const $invoice: any = this.$refs.invoice;
      const { exhibitionName, exhibitionId } = this.exhibitionInfo;
      // const { fairId } = this.$data;
      const { year, name, price, id } = this.info as any;

      const params: Record<string, any> = {
        // ...this.ruleForm,
        exhibitionId,
        exhibitionName,
        userId,
        periodicalName: name,
        periodicalYear: year,
        totalAmount: price.toString(),
        osType: '2', // 1:h5  2:PC  3:小程序
        payType: '1',
        // isKp: $invoice.isKp ? '1' : '0',
        periodicalId: this.info.id.toString(),
      };
      console.log('276');
      // if ($invoice.isKp) {
      //   params.invoice = {
      //     ...$invoice.invoiceRuleForm,
      //   };
      // }
      await this.$store.commit('order/setPeriodical', params);

      this.$router.push(
        `/payment/?id=${exhibitionId}&periodicalId=${id}&mode=pdf`
      );
      // 埋点
      // window.trace?.upload({
      //   module: TraceCode.MO_FAIR_PERIODICAL,
      //   callbackCode: TraceCode.CC_SUBMIT_PERIODICAL,
      //   bizId: this.info.id,
      //   bizOrderPayType: TraceCode.PAY,
      // });
    },
    async handleSubmit() {
      const $orderform = this.$refs.orderform as ElForm;
      const isLogin = this.$cookies.get('access_token');
      if (!isLogin) {
        await this.$store.commit(
          'user/setOrigins',
          location.origin + this.$route.fullPath
        );
        await this.$store.commit('user/setAction', 3);
        await this.$store.commit(
          'user/setIndustryId',
          this.exhibitionInfo.industry1
        );

        this.toLogin();
        return;
      }
      if (isLogin) {
        this.submitData();
      }
    },

    toLogin() {
      // const { fullPath } = this.$route;
      this.$login.show();
      // this.$router.push({
      //   path: `/login?redirect=${encodeURIComponent(fullPath)}`,
      // });
    },
  },
});
