





































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { ResponseCode } from '~/util/constant';
import { blackList } from '@/util/blackFair';
import { bucketCdnHost, bucketHost } from '~/util/common';
import { TraceCode } from '~/util/trace';
import FairItem from '~/pages/home/component/FairItem.vue';
import News from '../../pages/news/component/NewsItem.vue';

export default Vue.extend({
  name: 'Booth',
  layout: 'layout2',
  components: { FairItem, News },
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const id = _.get(ctx, 'params.id', null);

    const res: any[] = await Promise.all([
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.post(`/api/newsApi/getNewsbyExhibitionId/`, {
        locale: langApi,
        exhibitionId: id,
        pageNum: 1,
        pageSize: 6,
      }),
    ]);

    const [fairRes, newsByFairIdRes] = res;

    let fair: Record<string, any> = {};
    let remind = false; // 是否提醒
    if (fairRes.code === ResponseCode.SUCCESS) {
      if (fairRes.data.boothInfo) {
        fairRes.data.boothInfo = JSON.parse(fairRes.data.boothInfo);
      } else {
        fairRes.data.boothInfo = [];
      }
      fair = fairRes.data || {};
      fair.boothPrice1 = '';
      fair.boothPrice2 = '';
      remind = fairRes.data.follow; // 1: 提醒 0: 未提醒
      if (fair.boothInfo && fair.boothInfo.length > 0) {
        fair.boothInfo.forEach((item: any) => {
          if (item.priceType === '标准展位') {
            fair.boothPrice1 = item.price;
          }
          if (item.priceType === '光地展位') {
            fair.boothPrice2 = item.price;
          }
        });
      }
    }

    // 展会资讯
    let fairNews: any[] = [];
    let total: number = 0;
    if (newsByFairIdRes.code === ResponseCode.SUCCESS) {
      console.log(123213123);
      console.log(newsByFairIdRes);
      fairNews = newsByFairIdRes.data.records.map((n: any) => ({
        newsId: n.id,
        viewUrl: n.viewUrl,
        newsName: n.title,
        views: n.viewCount,
        logo: n.coverImg,
        publishTime: n.publishTime,
        description: n.description,
      }));
      total = newsByFairIdRes.data.total;
    }
    return {
      langApi,
      fair,
      remind,
      fairNews,
      total,
    };
  },
  data(): Record<string, any> {
    return {
      blackList,
      fair: {},
      exhibitionList: [],
    };
  },
  filters: {
    industry(data: string): string {
      try {
        return data.split(',')[0] + '展会';
      } catch (error) {
        if (process.client && 'fundebug' in window) {
          window.fundebug.notifyError(error);
        }
        return data + '展会';
      }
    },
    logo(value: string): string {
      let parse: any = value;
      if (typeof value === 'string') {
        try {
          parse = JSON.parse(value)[0]
            .replace('http://', 'https://')
            .replace(bucketHost, bucketCdnHost);
        } catch (error) {
          parse = value;
          console.error(error);
        }
      }
      return parse + '!logo';
    },
  },
  computed: {},
  created() {
    this.getintyExh();
  },
  mounted() {
    window.trace?.upload({
      module: TraceCode.MO_COMMON,
      callbackCode: TraceCode.CC_FAIR_DETAIL,
      bizId: this.$route.params.id,
    });
  },
  methods: {
    async getintyExh() {
      const res = await this.$axios.post('/api/exhibition/list', {
        locale: this.langApi,
        industryId: this.fair.industry1,
        areaType: this.fair.regionType,
        area: this.fair.pavilionInfo.areaId,
        selectTimeType: 1,
        pageNum: 1,
        pageSize: 8,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.exhibitionList = res.data.records;
      }
    },
    async handleFollowChange(val: number) {
      const res = await this.$axios.post(
        `/api/newsApi/getNewsbyExhibitionId/`,
        {
          locale: this.langApi,
          exhibitionId: this.fair.exhibitionId,
          pageNum: val,
          pageSize: 6,
        }
      );
      if (res.code === ResponseCode.SUCCESS) {
        this.$set(this, 'fairNews', res.data.records);
        this.$set(this, 'total', res.data.total);
      }
    },
  },
  head(): Record<string, any> {
    const { exhibitionName, area } = this.$data.fair;
    let title = `${exhibitionName}_展会资讯-聚展`;
    let keywords = `${exhibitionName}_展会资讯-聚展`;
    let description = `${exhibitionName}_展会资讯-聚展`;
    return {
      title,
      meta: [
        {
          name: 'keywords',
          content: keywords,
        },
        {
          name: 'description',
          content: description,
        },
      ],
    };
  },
});
