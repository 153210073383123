




































































































































































































































































import _ from 'lodash';
import Vue from 'vue';
import { mapState } from 'vuex';
import { message } from '~/util/common';
import TopBg from '../fair/component/detailTopBg.vue';
import { ResponseCode } from '~/util/constant';
import FairItem from '~/pages/home/component/FairItem.vue';
import Pay from './component/pay.vue';

export default Vue.extend({
  name: 'MerchantList',
  layout: 'layout2',
  components: { TopBg, FairItem, Pay },
  async asyncData(ctx) {
    const lang = _.get(ctx, 'query.lang', null);
    let langApi = 'cn';
    if (lang == 'zh') langApi = 'cn';
    if (lang == 'en') langApi = 'en';
    const id = _.get(ctx, 'params.id', null);
    const periodicalId = _.get(ctx, 'query.periodicalId', null);
    const periodicalName = _.get(ctx, 'query.name', null);
    const requestList = [
      ctx.$axios.get(`/api/exhibition/${id}`),
      ctx.$axios.get(`/api/periodicalApi/exhibitionId/${id}`), //
      ctx.$axios.get(`/api/periodicalApi/periodicalId/${periodicalId}`), // 会刊详情
    ];

    const reps: any = await Promise.all(requestList);

    const [fairRes, periodicalRes, periodicalResult] = reps;

    let fair: any = {};
    if (fairRes.code === ResponseCode.SUCCESS) {
      console.log('171-171', fairRes);
      fair = fairRes.data || {};
      // online 1:上架 2:未上架
      if (!_.isNil(fair.online) && fair.online !== 1 && id !== '4') {
        return ctx.error({
          message: 'Page not found',
          path: ctx.route.fullPath,
          statusCode: 404,
        });
      }
    } else {
      return ctx.error({
        message: 'Page not found',
        path: ctx.route.fullPath,
        statusCode: 404,
      });
    }

    let periodical = {};
    const searchObj: Record<string, any> = {
      exhibitionId: id,
      booth: '',
      productName: '',
      pageNum: 1,
      pageSize: 20,
    };
    console.log('301-301', periodicalRes);
    if (periodicalRes.code === ResponseCode.SUCCESS) {
      periodical = periodicalRes.data || {};
      searchObj.periodicalId = periodicalId
        ? +periodicalId
        : _.get(periodicalRes, 'data.records[0].id');
    }

    let periodicalDetail = null;
    console.log('311会刊详情', periodicalResult);
    if (periodicalResult.code === ResponseCode.SUCCESS) {
      periodicalDetail = periodicalResult.data || {};
    }

    let exhibitor = {};
    let params = JSON.parse(JSON.stringify(searchObj));
    for (let i in params) {
      if (!params[i]) delete params[i];
    }
    if (searchObj.periodicalId) {
      console.log('参展商名录列表-参数', params);
      const resps = await Promise.all([
        ctx.$axios.post<any, any>(`/api/exhibitorApi/list`, params),
      ]);

      const [exhibitorRes] = resps;
      console.log('参展商名录列表-2', exhibitorRes);
      console.log('参展商名录列表-3', exhibitorRes.data.records);
      if (exhibitorRes.code === ResponseCode.SUCCESS) {
        exhibitor = exhibitorRes.data || {};
      }
    }

    return {
      langApi,
      fairId: id,
      periodicalName,
      detail: fair,
      searchObj,
      periodical,
      exhibitor,
      periodicalId,
      periodicalDetail,
    };
  },

  data(): any {
    return {
      thStyle: {
        // background: '#da4009',
        background: '#f5f7fa',
        color: '#333',
        fontSize: '18px',
        padding: '30px 0',
        // ebeef5
      },
      loading: false,
      hasOrder: false,
      fair: [],
      showPay: false,
    };
  },

  computed: {
    ...mapState({
      isLogin: (state: any) => state.global.isLogin,
    }),
    periodicalList(): Record<string, any>[] {
      if (this.periodical) {
        return this.periodical?.records || [];
      }
      return [];
    },
    tableData(): Record<string, any>[] {
      if (this.exhibitor) {
        // productName
        let list = this.exhibitor?.records;
        list.map((item: any) => {
          item['productList'] = item.productName
            ? item.productName.split(',')
            : [];
        });
        return list; // this.exhibitor?.records || [];
      }
      return [];
    },
  },
  filters: {
    getCompanyName(str: string) {
      return str ? str.slice(0, 2) : '';
    },
  },
  created() {
    this.fetchHasOrder();
    this.getintyExh();
  },
  methods: {
    handlePayClose() {
      this.showPay = false;
    },
    async fetchHasOrder() {
      const res = await this.$axios.post('/api/exhibitorApi/checkUser', {
        periodicalId: this.periodicalId,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.hasOrder = res.data;
      }
    },
    async getintyExh() {
      const res = await this.$axios.post('/api/exhibition/list', {
        locale: this.langApi,
        industryId: this.detail.industry1,
        areaType: this.detail.regionType,
        area: this.detail.pavilionInfo.areaId,
        pageNum: 1,
        selectTimeType: 1,
        pageSize: 6,
      });
      if (res.code === ResponseCode.SUCCESS) {
        this.fair = res.data.records;
        console.log(this.fair);
      }
    },
    async handlePeriodicalChange(e: number) {
      this.searchObj.pageNum = 1;
      this.searchObj.periodicalId = e;
      this.periodicalId = e;
      await this.fetchHasOrder();
      await this.fetchExhibitorList();
    },
    toCompanyDetail(id: string) {
      if (!this.isLogin) {
        return this.$login.show();
      }
      window.open(
        `/company/${id}.html?periodicalId=${this.periodicalId}&fairId=${this.fairId}`
      );
    },
    handleLogin() {
      if (!this.isLogin) {
        return this.$login.show();
      }
      if (!this.hasOrder) {
        // window.open(
        //   `/periodical/${this.fairId}.html?periodicalId=${this.periodicalId}`
        // );
        this.showPay = true;
      }
    },
    handlePageChange(page: number) {
      if (this.isLogin) {
        if (!this.hasOrder) {
          return message.call(this, {
            delay: 3000,
            title: '请先购买',
            type: 'error',
          });
        }
        this.searchObj.pageNum = page;
        this.fetchExhibitorList();
      } else {
        this.handleLogin();
      }
    },
    async fetchExhibitorList() {
      this.loading = true;

      let params: any = JSON.parse(JSON.stringify(this.searchObj));
      for (let i in params) {
        if (!params[i]) delete params[i];
      }
      const res = await this.$axios.post('/api/exhibitorApi/list', params);
      this.loading = false;
      if (res.code === ResponseCode.SUCCESS) {
        this.exhibitor = res.data || {};
      }
    },
    handleReset() {
      this.searchObj.pageNum = 1;
      this.searchObj.exhibitorName = '';
      this.searchObj.booth = '';
      this.searchObj.productName = '';
      this.searchObj.exhibitorCountry = '';

      this.fetchExhibitorList();
    },
    handleSearch() {
      this.searchObj.pageNum = 1;
      if (!this.hasOrder) {
        return message.call(this, {
          delay: 3000,
          title: '请先购买',
          type: 'error',
        });
      }
      this.fetchExhibitorList();
    },
  },
  head(): Record<string, any> {
    const {
      startTime = '',
      endTime = '',
      exhibitionName = '',
      // industry = '',
      organizer = '',
      pavilionInfo = {},
    } = this.$data.detail;
    const { pavilionName = '' } = (pavilionInfo || {}) as Record<string, any>;
    // const industrys = industry?.split(',');
    // const industryName = _.get(industrys, '[0]', '');
    return {
      title: `${exhibitionName}_展商名录-聚展网`,
      meta: [
        {
          name: 'keywords',
          content: `${exhibitionName}展会展商名录如何获取,参展企业有哪些,exhibitor list`,
        },
        {
          name: 'description',
          content: `展会时间:${startTime}至${endTime},展会地点:${pavilionName},主办单位:${organizer},聚展网提供展位预订,展会门票,展会行程,展会搭建等服务`,
        },
      ],
    };
  },
});
